import './index'
import React from 'react';
import HeaderComponent from '../Header';
import FooterComponent from '../Footer';

class MainLayout extends React.Component {
    state = {
    }

    render() {
        return (
            <>
                <HeaderComponent />
                {this.props.children}
                <FooterComponent />
            </>
        )
    }
}
export default MainLayout;