import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Spin } from 'antd';
import MainLayout from './components/Layout';
// import Home from '@/pages/Home';
// import Cir from '@/pages/Product/Cir/index';
// import ServicePolicy from '@/pages/ServiceSupport/ServicePolicy';
// import CompanyProfile from '@/pages/About/CompanyProfile';
// import JoinUs from '@/pages/About/JoinUs';
// import PositionDetail from '@/pages/About/PositionDetail';
// import Cirdemo from '@/pages/TypicalDemo/CirDemo';
// import NewsTemplate from '@/pages/News/NewsTemplate';
// import Bms from '@/pages/Product/Bms/index';
// import BmsDemo from '@/pages/TypicalDemo/BmsDemo';
// import Oir from '@/pages/Product/Oir/index';
// import Factory from '@/pages/Product/Factory';
// import FactoryDemo from '@/pages/TypicalDemo/FactoryDemo';
// import Bir from '@/pages/Product/Bir';
// import BirDemo from '@/pages/TypicalDemo/BirDemo';
// import Honors from '@/pages/About/Honors'

const Home = lazy(() => import('@/pages/Home/index.js'));
const Cir = lazy(() => import('@/pages/Product/Cir/index'));
const ServicePolicy = lazy(() => import('@/pages/ServicePolicy'));
const CompanyProfile = lazy(() => import('@/pages/About/CompanyProfile'));
const JoinUs = lazy(() => import('@/pages/About/JoinUs'));
const PositionDetail = lazy(() => import('@/pages/About/PositionDetail'));
const Cirdemo = lazy(() => import('@/pages/TypicalDemo/CirDemo'));
const NewsTemplate = lazy(() => import('@/pages/NewsTemplate'));
const Bms = lazy(() => import('@/pages/Product/Bms/index'));
const BmsDemo = lazy(() => import('@/pages/TypicalDemo/BmsDemo'));
const Oir = lazy(() => import('@/pages/Product/Oir/index'));
const Factory = lazy(() => import('@/pages/Product/Factory'));
const FactoryDemo = lazy(() => import('@/pages/TypicalDemo/FactoryDemo'));
const Bir = lazy(() => import('@/pages/Product/Bir'));
const BirDemo = lazy(() => import('@/pages/TypicalDemo/BirDemo'));
const Honors = lazy(() => import('@/pages/About/Honors'));

class RouterConfig extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <MainLayout>
                        <Suspense fallback={<div style={{ textAlign: 'center', marginTop: '100px', marginBottom: '100px' }}><Spin size="large" /></div>}>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/product-center/cir' component={Cir} />
                            <Route exact path='/serviceSupport' component={ServicePolicy} />
                            <Route exact path='/about-us/recruitment' component={JoinUs} />
                            <Route exact path='/about-us/qkIntroduction' component={CompanyProfile} />
                            <Route exact path='/about-us/recruitment/detail' component={PositionDetail} />
                            <Route exact path='/news/companyDynamics/:newsID' component={NewsTemplate} />
                            <Route exact path='/product-center/numImg' component={Bms} />
                            <Route exact path='/product-center/oir' component={Oir} />
                            <Route exact path='/product-center/bir' component={Bir} />
                            <Route exact path='/product-center/dataFactory' component={Factory} />
                            <Route exact path='/typicalDemo/bir' component={BirDemo} />
                            <Route exact path='/typicalDemo/cir' component={Cirdemo} />
                            <Route exact path='/typicalDemo/bms' component={BmsDemo} />
                            <Route exact path='/typicalDemo/factory' component={FactoryDemo} />
                            <Route exact path='/about-us/honors' component={Honors} />
                        </Suspense>
                    </MainLayout>
                </Switch>
            </BrowserRouter>
        )
    }
}
export default RouterConfig;