import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import RouterConfig from './router';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <RouterConfig />,
  document.getElementById('root')
);

serviceWorker.unregister();
