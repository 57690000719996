import './index.scss';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logoImg, routeData } from '@/common'

function Header() {
    const [navName, setNavName] = useState('');
    const pathName = window.location.pathname;

    useEffect(() => {
        routeData.forEach(item => {
            const { path } = item;
            if (pathName.includes(path)) {
                setNavName('navName')
            }
        })
    }, [pathName])

    return (
        <div className='header-warp'>
            <div className="header">
                <div className='header-logo'>
                    <a href='/'>
                        <img src={logoImg} alt='logo'></img>
                        <span className='company-name'>湖南桥康智能科技有限公司</span>
                    </a>
                </div>
                <div className='header-route'>
                    {
                        routeData.map(item => {
                            const { routes, path, name } = item;
                            const includesNews = path.includes('/news/companyDynamics');
                            if (routes && routes.length > 0) {
                                return (
                                    <div className="header-name" key={path}>
                                        <span className={`${pathName.includes(path) ? navName : 'header-name-a'}`}>
                                            {name}
                                        </span>
                                        <div className='header-second-nav'>
                                            {
                                                routes.map(item => {
                                                    const { path, name } = item;
                                                    if (path === '/product-center/app' || path === '/product-center/bim') { // 产品中心暂未内容二级导航处理
                                                        return (
                                                            <span className={`special-span ${pathName === path ? 'nav-a' : 'nav-a-hover'}`} key={path}>
                                                                {name}
                                                            </span>
                                                        )
                                                    }
                                                    return (
                                                        <Link className={`${pathName === path ? 'nav-a' : 'nav-a-hover'}`} to={path} key={path}>
                                                            {name}
                                                        </Link>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            } else if (includesNews) { // 特殊处理点击公司新闻选中状态
                                return (
                                    <div className="header-name" key={path}>
                                        <Link className={`${pathName.includes('/news/companyDynamics') ? navName : 'header-name-a'}`} to={path}>
                                            {name}
                                        </Link>
                                    </div>
                                )
                            }
                            return (
                                <div className="header-name" key={path}>
                                    <Link className={`${pathName === path ? navName : 'header-name-a'}`} to={path}>
                                        {name}
                                    </Link>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Header;