// 图片地址基础部分
const baseUrl = 'https://bim-1302331233.cos.ap-chengdu.myqcloud.com/site/images/webImgs/';
export const logoImg = `${baseUrl}logo.png`;
// 首页 ---- 跑马灯
export const carousel1 = `${baseUrl}carousel/Carousel1.png`;
export const carousel2 = `${baseUrl}carousel/Carousel2.png`;
export const carousel3 = `${baseUrl}carousel/Carousel3.jpg`;
export const carousel4 = `${baseUrl}carousel/Carousel4.jpg`;
// 底部组件 ---- 二维码
export const wxImg = `${baseUrl}otherImgs/wx.jpeg`;
export const wxkfImg = `${baseUrl}otherImgs/wxkf.png`;
export const dyImg = `${baseUrl}otherImgs/dy.jpeg`;
// 首页 ---- 产品中心7张图片
const birImg = `${baseUrl}sevenProduct/birImg.jpg`;
const cirImg = `${baseUrl}sevenProduct/cirImg.jpg`;
const oirImg = `${baseUrl}sevenProduct/oirImg.jpg`;
const numImg = `${baseUrl}sevenProduct/numImg.jpg`;
const appImg = `${baseUrl}sevenProduct/appImg.jpg`;
const dataFactoryImg = `${baseUrl}sevenProduct/dataFactoryImg.jpeg`;
const bimImg = `${baseUrl}sevenProduct/bimImg.jpg`;
// 首页---- 典型案例图片
const bmsDemoImg = `${baseUrl}sevenProductDemo/bms-demo.png`;
const appDemoImg = `${baseUrl}sevenProductDemo/appImg-demo.jpg`;
const bimDemoImg = `${baseUrl}sevenProductDemo/bimImg-demo.png`;
const oirDemoImg = `${baseUrl}sevenProductDemo/oir-demo.jpg`;
// 产品中心 ---- cir产品介绍
export const cirFeaturesImg = `${baseUrl}cir-features.png`;
// 产品中心 ---- bir
const birProcessImg = `${baseUrl}birProduct/birProcess.png`
// 产品中心 ---- 数据工厂
const factoryBgImg = `${baseUrl}factoryProduct/factoryBg.png`;
const factoryArchitecture = `${baseUrl}factoryProduct/factoryArchitecture.png`;
const moduleImg01 = `${baseUrl}factoryProduct/factoryModule01.png`;
const moduleImg02 = `${baseUrl}factoryProduct/factoryModule02.png`;
const positionImg01 = `${baseUrl}factoryProduct/factoryModule03.png`;
const positionImg02 = `${baseUrl}factoryProduct/factoryModule04.png`;
const positionImg03 = `${baseUrl}factoryProduct/factoryModule05.png`;
const positionImg04 = `${baseUrl}factoryProduct/factoryModule06.png`;
const positionImg05 = `${baseUrl}factoryProduct/factoryModule07.png`;
const positionImg06 = `${baseUrl}factoryProduct/factoryModule08.png`;
const positionImg07 = `${baseUrl}factoryProduct/factoryModule09.png`;
const positionImg08 = `${baseUrl}factoryProduct/factoryModule10.png`;
const positionImg09 = `${baseUrl}factoryProduct/factoryModule11.png`;
// 服务与支持 ---- 营销网络
const marketNetImg = `${baseUrl}otherImgs/marketNetImg.jpeg`;
// ---- 新闻动态
const videoImg1 = `${baseUrl}news/video-1.jpg`;
// ---- 关于桥康公司俯瞰图
const overLookImg1 = `${baseUrl}overLookImgs/overlook-img1.png`;
const overLookImg2 = `${baseUrl}overLookImgs/overlook-img2.png`;
// ---- 关于桥康 ---- 招聘详情
export const zlImg = `${baseUrl}otherImgs/zl.png`;
// ---- 关于桥康 ---- 所获成果
const highTech = `${baseUrl}honors/highTech.png`;
const littleGiant = `${baseUrl}honors/littleGiant.png`;
const xGuWei01 = `${baseUrl}honors/xGuWei01.png`;
const xGuWei02 = `${baseUrl}honors/xGuWei02.jpg`;
const projectJD01 = `${baseUrl}honors/projectJd01.jpg`;
const projectJD02 = `${baseUrl}honors/projectJd02.jpg`;
const patent = `${baseUrl}honors/patent.png`;
const soft01 = `${baseUrl}honors/soft01.png`;
// ---- 典型案例 ---- bir
const birDemo01 = `${baseUrl}birDemo/bir-demo01.jpg`;
const birDemo02 = `${baseUrl}birDemo/bir-demo02.jpg`;
const birDemo03 = `${baseUrl}birDemo/bir-demo03.jpg`;
const birDemo04 = `${baseUrl}birDemo/bir-demo04.png`;
const birDemo05 = `${baseUrl}birDemo/bir-demo05.jpg`;
const birDemo06 = `${baseUrl}birDemo/bir-demo06.jpg`;
// ---- 典型案例 ---- cir
const cirdemo01 = `${baseUrl}cirDemo/cir-demo03.png`;
const cirdemo02 = `${baseUrl}cirDemo/cir-demo04.png`;
const cirdemo03 = `${baseUrl}cirDemo/cir-demo02.png`;
const cirdemo04 = `${baseUrl}cirDemo/cir-demo05.png`;
const cirdemo05 = `${baseUrl}cirDemo/cir-demo06.png`;
const cirdemo06 = `${baseUrl}cirDemo/cir-demo07.png`;
// ---- 典型案例 ---- bms
const bmsDemo01 = `${baseUrl}bmsDemo/bms-demo01.png`;
const bmsDemo02 = `${baseUrl}bmsDemo/bms-demo02.png`;
const bmsDemo03 = `${baseUrl}bmsDemo/bms-demo03.png`;
// ---- 典型案例 ---- 数据工厂
const factoryDemoImg01 = `${baseUrl}factoryDemo/factory-demo01.png`;
const factoryDemoImg02 = `${baseUrl}factoryDemo/factory-demo02.png`;
const factoryDemoImg03 = `${baseUrl}factoryDemo/factory-demo03.png`;
const factoryDemoImg04 = `${baseUrl}factoryDemo/factory-demo04.png`;
const factoryDemoImg05 = `${baseUrl}factoryDemo/factory-demo05.png`;
const factoryDemoImg06 = `${baseUrl}factoryDemo/factory-demo06.png`;
const factoryDemoImg07 = `${baseUrl}factoryDemo/factory-demo07.png`;
// ---- 新闻动态 ---- 长篇新闻img
const transformation01 = `${baseUrl}langNews/transformation01.jpg`;
const transformation02 = `${baseUrl}langNews/transformation02.jpg`;
const transformation03 = `${baseUrl}langNews/transformation03.jpg`;
const transformation04 = `${baseUrl}langNews/transformation04.jpg`;
const transformation05 = `${baseUrl}langNews/transformation05.png`;
const transformation06 = `${baseUrl}langNews/transformation06.jpg`;
const water01 = `${baseUrl}news/water01.jpg`;
const water02 = `${baseUrl}news/water02.jpeg`;

export const routeData = [{
        name: '首页',
        path: '/'
    },
    {
        name: '产品中心',
        path: '/product-center',
        routes: [{
                name: "BIR-X车载式桥梁智能检测机器人",
                path: '/product-center/bir'
            },
            {
                name: "CIR-X缆索智能检测机器人",
                path: '/product-center/cir'
            },
            {
                name: "OIR-X特大桥梁底自动化检测系统",
                path: '/product-center/oir'
            },
            {
                name: "DIC基于数字图像技术的公共设施轻量化安全监测平台",
                path: '/product-center/numImg'
            },
            {
                name: "三维桥检APP",
                path: '/product-center/app'
            },
            {
                name: "Factory桥梁智能检测数据工厂",
                path: '/product-center/dataFactory'
            },
            {
                name: "LwSBIM桥梁轻量化语义BIM病害管理系统",
                path: '/product-center/bim'
            }
        ]
    },
    {
        name: '服务与支持',
        path: '/serviceSupport'
    },
    {
        name: '新闻动态',
        path: '/news/companyDynamics/1'
    },
    {
        name: '关于桥康',
        path: '/about-us',
        routes: [{
                name: "桥康简介",
                path: '/about-us/qkIntroduction'
            },
            {
                name: "所获荣誉",
                path: '/about-us/honors'
            },
            {
                name: "招贤纳士",
                path: '/about-us/recruitment'
            }
        ]
    }
]

// ---- 首页 ---- 产品简介
export const productDescription = [{
        id: 1,
        title: [{
                key: 1,
                name: 'BIR-X'
            },
            {
                key: 2,
                name: '车载式'
            },
            {
                key: 3,
                name: '桥梁智能检测机器人'
            }
        ],
        name: 'BIR-X车载式',
        name1: '桥梁智能检测机器人',
        img: birImg,
        path: '/product-center/bir'
    },
    {
        id: 2,
        title: [{
                key: 1,
                name: 'CIR-X'
            },
            {
                key: 2,
                name: '缆索智能检测机器人'
            }
        ],
        name: 'CIR-X',
        name1: '缆索智能检测机器人',
        img: cirImg,
        path: '/product-center/cir'
    },
    {
        id: 3,
        title: [{
                key: 1,
                name: 'OIR-X'
            },
            {
                key: 2,
                name: '特大桥梁底'
            },
            {
                key: 3,
                name: '自动化检测系统'
            }
        ],
        img: oirImg,
        path: '/product-center/oir'
    },
    {
        id: 4,
        title: [{
                key: 1,
                name: 'DIC基于数字图像'
            },
            {
                key: 2,
                name: '技术的公共设施'
            },
            {
                key: 3,
                name: '轻量化安全监测平台'
            }
        ],
        img: numImg,
        path: '/product-center/numImg'
    },
    {
        id: 5,
        title: [{
            key: 1,
            name: '三维桥检APP'
        }],
        img: appImg,
        // path: '/product-center/app'
    },
    {
        id: 6,
        title: [{
                key: 1,
                name: 'Factory'
            },
            {
                key: 2,
                name: '桥梁智能'
            },
            {
                key: 3,
                name: '检测数据工厂'
            }
        ],
        img: dataFactoryImg,
        path: '/product-center/dataFactory'
    },
    {
        id: 7,
        title: [{
                key: 1,
                name: 'LwSBIM'
            },
            {
                key: 2,
                name: '桥梁轻量化语义'
            },
            {
                key: 3,
                name: 'BIM病害管理系统'
            }
        ],
        img: bimImg,
        // path: '/product-center/bim'
    }
]

// ---- 首页 ---- 典型案例
export const demoData = [{
        id: '1',
        name: '桥梁检测',
        img: carousel4,
        path: '/typicalDemo/bir'
    },
    {
        id: '2',
        name: '缆索检测',
        img: carousel3,
        path: '/typicalDemo/cir'
    },
    {
        id: '3',
        name: '特大桥检测',
        img: oirDemoImg,
        // path: '/news/typicalDemo'
    },
    {
        id: '4',
        name: '轻量化监测',
        img: bmsDemoImg,
        path: '/typicalDemo/bms'
    },
    {
        id: '5',
        name: '三维辅助检测',
        img: appDemoImg,
        // path: '/news/typicalDemo'
    },
    {
        id: '6',
        name: '数据工厂',
        img: positionImg07,
        path: '/typicalDemo/factory'
    },
    {
        id: '7',
        name: '管理系统',
        img: bimDemoImg,
        // path: '/news/typicalDemo'
    }
]

// ---- 首页 ---- 公司新闻
//! ---- 其中公司新闻 path 需要和 newTemplateData 中的id对应
export const news = [{
    id: 1,
    title: '公司新闻',
    content: [{
        key: 1,
        subTit: '大国桥梁梦·BIR-X车载式桥梁智能检测机器人开启桥梁检测智能化时代[视频]',
        path: '/news/companyDynamics/1',
    }, {
        key: 2,
        subTit: '大国桥梁梦·桥梁斜拉索检测“神器”：CIR-X缆索智能检测机器人[视频]',
        path: '/news/companyDynamics/2',
    }, {
        key: 3,
        subTit: '大国桥梁梦·桥康智能研发多型桥梁智能检测机器人系统[视频]',
        path: '/news/companyDynamics/3',
    }, {
        key: 4,
        subTit: '桥康智能·新一代人工智能技术助推桥梁管养转型',
        path: '/news/companyDynamics/4',
    }, {
        key: 5,
        subTit: '桥康故事·让中国桥梁更健康—杯水也要灭火',
        path: '/news/companyDynamics/5',
    }]
}, {
    id: 2,
    title: '行业资讯',
    content: [{
        key: 9,
        subTit: '智慧潮“甬”丨宁波迎来第三届大数据与智慧公路养护管理技术论坛',
        path: 'https://mp.weixin.qq.com/s/6c5oI3pGV4QZrb7mkTgDpA',
    }, {
        key: 1,
        subTit: '交通部 ：BIR-X车载式桥梁智能检测机器人完成广昆高速公路高架桥检测工作',
        path: 'http://www.mot.gov.cn/tupianxinwen/201901/t20190128_3161397.html',
    }, {
        key: 2,
        subTit: '我国自行研制车载式桥梁智能检测机器人在湘皖粤等省现场检测获得成功',
        path: 'http://www.zgjtb.com/2019-01/23/content_218161.htm',
    }, {
        key: 3,
        subTit: '高大上！给桥梁做“B超”，湖南高速用上了机器人',
        path: 'https://mp.weixin.qq.com/s/A73mZ5_GnnYrDZ4bBKAAbw',
    }, {
        key: 4,
        subTit: '经视新闻平江：桥梁“体检”用上机器人  “变形金刚”轻松搞定',
        path: 'https://www.iqiyi.com/v_19rr0m3ejg.html',
    }, {
        key: 5,
        subTit: '滨州分公司率先采用缆索检测机器人技术对滨州黄河大桥部分斜拉索及上部减震器进行检查',
        path: 'https://mp.weixin.qq.com/s/Hwy7Yo6H2iPPDsLisOWEUQ',
    }, {
        key: 6,
        subTit: '泉州晋江大桥接受全面检查 6月1日至4日每日0时—6时全封闭交通管制',
        path: 'http://www.qzwb.com/qzfb/content/2020-05/27/content_7033886.htm',
    }, {
        key: 7,
        subTit: '晋江大桥迎来斜拉索检测 机器人“小黑”帮了大忙',
        path: 'https://www.qztv.cn/index/News/detail/id/wq95wqbDnMKyd8KiwqzChnt0w5nChcKofcObwoLCnMKuw53Cg8OMcXA.html',
    }, {
        key: 8,
        subTit: '2019年交通运输行业发展统计公报',
        path: 'http://xxgk.mot.gov.cn/jigou/zhghs/202005/t20200512_3374322.html',
    }]
}]
// ---- Bir ---- 产品中心Bir
export const birProductData = [{
    id: 1,
    title: '产品简介',
    subTit: 'BIR-X车载式桥梁智能检测机器人',
    content1: 'BIR-X桥梁智能检测机器人，面向高速公路桥梁定期健康检查、桥梁全生命周期管养、预防性养护，为获取有精准三维位置、可量测的数字化病害数据而研发。机器人搭载线性激光传感器，可快速获取桥梁断面参数进而构建桥梁三维模型，再利用快速自动化采集点规划算法进行采集点规划，最后由自动控制采集系统对机器人进行控制，该系统实现了对机器人沿桥长方向及桥宽方向往复运动和采集末端位姿的精准控制、自动化地完成规划点数据的采集存储工作。采集工作具有流程化、操作简单、人工干预少等优点，是新一代采集桥梁数字化病害的桥梁检测装备。'
}, {
    id: 2,
    title: '技术参数',
    skillParams: [{
            key: 1,
            col1: '作业幅度',
            col2: '6～15 m'
        },
        {
            key: 2,
            col1: '采集效率',
            col2: '10～30 m/min'
        },
        {
            key: 3,
            col1: '作业占道',
            col2: '2.5 m'
        },
        {
            key: 4,
            col1: '跨越人行道宽度',
            col2: '0.5～5 m'
        },
        {
            key: 5,
            col1: '裂缝病害识别精度',
            col2: '≤0.2mm'
        }
    ]
}, {
    id: 3,
    title: '技术性能',
    skillContent: [{
            key: 1,
            content: '1.自动快速构建桥梁三维模型'
        },
        {
            key: 2,
            content: '2.自动快速规划采集点位'
        },
        {
            key: 3,
            content: '3.自动化执行规划采集数据'
        },
        {
            key: 4,
            content: '4.适用桥型 T梁、小箱梁、空心板、等截面箱梁'
        },
        {
            key: 5,
            content: '5.采集部位 上部结构、横隔板'
        }
    ]
}, {
    id: 4,
    title: '数据采集作业流程',
    imgs: [{
            key: 1,
            imgUrl: birProcessImg
        },
        {
            key: 2,
            imgIntro: '一键展臂作业准备--桥梁轻量化BIM模型在线构建--自适应桥梁结构最优路径规划--无人值守全自动数据采集'
        }
    ]
}]
// ---- 典型案例 ---- Bir
export const birDemoData = [{
    id: 1,
    title: 'BIR-X车载式桥梁智能检测机器人系统，已在湖南、广东、安徽3个省300多座桥梁进行了实际检测，检测效果完美解决传统人工检测方式存在的行业痛点。',
    imgs: [{
            key: 1,
            imgUrl: birDemo01
        },
        {
            key: 2,
            imgUrl: birDemo02
        },
        {
            key: 3,
            imgUrl: birDemo03
        },
        {
            key: 4,
            imgUrl: birDemo04
        },
        {
            key: 5,
            imgUrl: birDemo05
        },
        {
            key: 6,
            imgUrl: birDemo06
        }
    ]
}]
// ---- Cir ---- cir介绍
export const cirIntro = {
    id: 1,
    content: 'CIR-X缆索智能检测机器人，面向斜拉索和悬索桥缆索检测、监测和维护而研发。机器人可沿缆索表面自动远距离爬行，快速对缆索、索塔及锚固区表观进行检测，实现数据自动采集、数据智能分析与处理、完整缆索特征重构、自动生成检测报告，是新一代高效、智能、攀爬式缆索检测机器人。'
}

// ---- cir ---- 技术参数
export const cirTechnologyParams = [{
        id: 1,
        name: '检测缆索直径',
        performance: '50 ~ 260 mm'
    },
    {
        id: 2,
        name: '检测作业速度',
        performance: '10 ～ 30 m/min'
    },
    {
        id: 3,
        name: '检测缆索长度',
        performance: '1000 m'
    },
    {
        id: 4,
        name: '检测缆索最大斜度',
        performance: '> 75 °'
    },
    {
        id: 5,
        name: '无线传输距离',
        performance: '1000 m'
    },
    {
        id: 6,
        name: '电池续航里程',
        performance: '10000 m'
    },
    {
        id: 7,
        name: '越障空间',
        performance: '140 * 110 mm'
    },
    {
        id: 8,
        name: '作业环境温度',
        performance: '-20 ~ 60 °C'
    },
    {
        id: 9,
        name: '工作电压',
        performance: '24 v'
    },
    {
        id: 10,
        name: '整机重量',
        performance: '25 kg'
    }
]
// ---- cir ---- cir优势
export const cirAdvantage = [{
        id: 1,
        name: '覆盖大范围斜拉索桥梁缆索直径'
    },
    {
        id: 2,
        name: '跨越缆索表面监测传感器'
    },
    {
        id: 3,
        name: '超长的连续作业续航里程'
    },
    {
        id: 4,
        name: '超强的检测能力和效率'
    },
    {
        id: 5,
        name: '极其简便的机器人操控'
    },
    {
        id: 6,
        name: '高效、轻便、智能化数据采集技术'
    },
    {
        id: 7,
        name: '机器深度学习的数据处理技术'
    },
    {
        id: 8,
        name: '行业领先的桥梁数据库管理平台'
    }
]
// ---- cir ---- cir产品功能
export const cirFeatures = [{
    id: 1,
    tit: '主要功能',
    content: '缆索表观检测、索塔表观检测 '
}, {
    id: 2,
    tit: '扩展功能',
    content: '索力检测、断丝检测、PE修补缠包带修复、表层清洁'
}]

// ---- cir ---- cir典型案例
export const productCase = [{
    id: '2',
    title: '浙江舟山｜桃夭门大桥',
    content1: '浙江舟山桃夭门大桥属跨海大桥，主桥为(48 + 48 + 50 + 580 + 50 + 48 +48)米双塔双索面 7 跨连续半漂浮体系混合式斜拉桥。斜拉索共计168根，直径范围90-140mm，长度范围283-50米，绝大多数斜拉索设有阻尼器和加速度传感器，检测难度系数较大。',
    content2: '我公司研发的CIR-X缆索智能检测机器人具有整机重量轻、检测直径范围大、爬行距离长、跨越障碍物能力强等特点，现场两人即可快捷作业，每天可检测35根斜拉索，仅用5天时间完成该桥梁全部斜拉索的数据采集工作，后期进行通过图像深度学习处理软件实现数据处理、自动识别病害、自动生成检测报告，项目组浙江舟山桃夭门大桥成功完成了缆索智能检测项目的应用示范。',
    imgsData: [{
            key: 1,
            imgUrl: cirdemo01
        },
        {
            key: 2,
            imgUrl: cirdemo02
        },
        {
            key: 3,
            imgUrl: cirdemo03,
            tit: '数据采集场景'
        },
        {
            key: 4,
            imgUrl: cirdemo04,
            tit: '数据处理过程'
        },
        {
            key: 5,
            imgUrl: cirdemo05,
            tit: '自动识别病害'
        },
        {
            key: 6,
            imgUrl: cirdemo06,
            tit: '自动生成检测报告'
        }
    ]
}]

// ---- 基于数字图像技术的公共设施轻量化安全监测平台  ---- bms产品介绍
export const bmsProductData = [{
    id: 1,
    title: '产品简介',
    subTit: 'DIC基于数字图像技术的公共设施轻量化安全监测平台',
    content1: '融合数字图像（dic）、物联网、云平台、大数据、边缘计算、4G/5G等信息技术，对特大桥桥塔、索（杆）、梁体等重要部位和阻尼器、伸缩缝、支座等重要构件的时程位移等信息实现基于视频的可视化、数字化监测的一套新型监测系统，可更精准、更高效、更低成本的实现特桥梁实时安全监测和预测预警。'
}, {
    id: 2,
    title: '技术参数',
    skillParams: [{
            key: 1,
            col1: '测量精度：',
            col2: '亚像素级'
        },
        {
            key: 2,
            col1: '测量距离：',
            col2: '0.5-200m'
        },
        {
            key: 3,
            col1: '采样频率：',
            col2: '30fps'
        },
        {
            key: 4,
            col1: '同时测点数量：',
            col2: '任意点数'
        },
        {
            key: 5,
            col1: '作业视场：',
            col2: '50*50m'
        }
    ]
}, {
    id: 3,
    title: '技术性能',
    skillContent: [{
            key: 1,
            content: '1、数据轻：自动边缘计算,无效数据边缘过滤'
        },
        {
            key: 2,
            content: '2、硬件轻：传感器所见区域即可数字化表达,监测范围更大,内容更丰富,少量硬件即可覆盖主要监测区域'
        },
        {
            key: 3,
            content: '3、工作量轻：非接触式安装,安装维护方便'
        },
        {
            key: 4,
            content: '4、费用轻：硬件可靠,传感器数量少,维护工作小,综合费用低'
        },
        {
            key: 5,
            content: '5、边界影响轻：核心算法保证亚像素级别特征识别,模态识别消除边界影响'
        }
    ]
}, {
    id: 4,
    title: '主要功能',
    featuresContent: [{
            key: 1,
            col1: '主要功能',
            col2: '扩展功能'
        },
        {
            key: 2,
            col1: '桥梁结构变形监测与预警',
            col2: '边坡变形监测、大坝变形监测、高层建筑变形监测'
        }
    ]
}]

// ---- 轻量化检测 ---- bms典型案例
export const bmsCaseData = [{
    id: '4',
    title: '广东珠江黄埔大桥',
    content1: '黄埔大桥全长18.694千米，主桥段总长约7.0165千米；黄埔大桥北汊桥北段主跨383米，向南在锚跨布置两个辅助墩，边跨为（197+63+62）米形式；全桥共有50段钢箱梁，每段标准长16米、宽41.1米、高3.5米。大桥塔柱从承台起至塔顶高度226.114米，塔柱设两道高10米的横梁；两主塔承台均为边长19米、厚6米长方体，承台之间采用高6米、宽8米的系梁联成整体；索塔共有88根钢绞线斜拉索，最长约391米。 黄埔大桥南汊桥主跨1108米，北边跨290米、南边跨350米；全桥共有87段钢箱梁，每段标准长12.8米、宽41.69米、高3.5米。大桥塔柱从承台起至塔顶190.476米，承台为边长19米、高6米长方体，塔柱与承台之间设长17米、宽15米、高2米的塔座。大桥主缆矢跨比1:10，主缆横桥向中心间距36.5米，吊索顺桥向标准间距12.8米，每根主缆有通长索股147根，北边跨另设6根背索、南边跨另设2根背索。',
    content2: '我公司开发的轻量化安全监测系统融合数字图像、物联网、云平台、大数据、边缘计算、4G/5G等信息技术，对重要构件的时程位移等信息实现基于视频的可视化、数字化监测的一套新型监测系统，可更精准、更高效、更低成本的实现特桥梁实时安全监测和预测预警。',
    imgData: [{
            key: 1,
            img: bmsDemo01,
            tit: '友好用户界面'
        },
        {
            key: 2,
            img: bmsDemo02,
            tit: '传感器布设示意图'
        },
        {
            key: 3,
            img: bmsDemo03,
            tit: '基于数字图像技术的非接触式监测新技术与传统技术结果对比'
        }
    ]
}]

// ---- 特大桥梁底监测 ---- Oir产品简介
export const oirProductData = [{
        id: 1,
        title: '产品简介',
        subTit: 'OIR-X特大桥梁底自动化检测系统',
        content1: '适应行业政策和行业供给侧改革要求，OIR-X特大桥梁底智能检测机器人基于机器视觉、深度学习等新一代人工智能技术，对桥梁底部进行自动化快速扫描，实现桥梁底部图像的自动采集及表面裂缝形状、位置的动态检测，同时构建出桥梁底面的三维模型，进行三维仿真显示。实现桥梁数据采集智能化，为养护分析决策、养护资金优化分配，并进而建立全寿命养护效益评价模型创造了条件，促进了行业技术水平的提升。'
    },
    {
        id: 2,
        title: '技术参数',
        skillParams: [{
                key: 1,
                col1: '图像采集综合效率：',
                col2: '8min/跨（50m）'
            },
            {
                key: 2,
                col1: '像素空间分辨能力：',
                col2: '0.075-0.15mm'
            },
            {
                key: 3,
                col1: '裂缝识别宽度：',
                col2: '0.2mm'
            },
            {
                key: 4,
                col1: '空间定位精度：',
                col2: '5cm'
            }
        ]
    },
    {
        id: 3,
        title: '技术性能',
        skillPerformance: [{
                key: 1,
                content: '1、多自由度组网，梁底可视区域无死角全覆盖'
            },
            {
                key: 2,
                content: '2、多传感联合定位导航，病害与部构件精准关联'
            },
            {
                key: 3,
                content: '3、轻量化设计，不影响交通正常通行'
            },
            {
                key: 4,
                content: '4、分布式安全管理机制，实现自动化数据采集'
            },
            {
                key: 5,
                content: '5、多传感与三维模型融合，实现最优路径规划'
            }
        ]
    },
    {
        id: 4,
        title: '主要功能',
        featuresContent: [{
                key: 1,
                col1: '主要功能',
                col2: '扩展功能'
            },
            {
                key: 2,
                col1: '主梁底部表观检测',
                col2: '混凝土强度检测、钢筋锈蚀检测、钢筋分布、混凝土保护层厚度检测'
            }
        ]
    }
]
// ---- 产品中心 ---- 桥梁智能监测数据工厂
export const factoryProductIntro = [{
    id: 1,
    title: '产品简介',
    subTit: 'Factory桥梁智能检测数据工厂',
    content: [{
            key: 1,
            main: '桥梁智能检测数据工厂旨在接收本公司各类自动化检测装备获取的桥梁表观数据，采用摄影测量、计算机视觉、人工智能、计算机图形学等各领域的技术，对多源传感器获取的数据进行融合、筛选、拼接、标记、特征提取等深度处理，为桥梁科学管养系统提供真实、无损的三维可视化病害数据。'
        },
        {
            key: 2,
            main: '数据工厂的总体平台特点如下：'
        },
        {
            key: 3,
            main: '(1) 海量数据管理，支持3000张以上5k图像数据的检索和浏览；'
        },
        {
            key: 4,
            main: '(2) 厘米级别的病害定位精度，毫米级别的裂缝病害量测精度；'
        },
        {
            key: 5,
            main: '(3) 基于AI的多类型桥梁病害快速筛选，识别正确率超过90%；'
        },
        {
            key: 6,
            main: '(4) 高精度(10亿+像素)图像拼接'
        }
    ],
    porjectBg: {
        title: '项目背景',
        projectBgSubTit: '桥梁智能检测数据工厂提出的背景如下图所示：',
        projectBgImg: factoryBgImg,
    },
    projectArchitecture: {
        title: '总体架构',
        projecyArchSubTit: '桥梁智能检测数据工厂位于本公司面向科学管养的桥梁检测大平台中的处理层，向上承载各类自动化检测装备获取的数据，向下输出有效的处理数据。',
        projecyArchImg: factoryArchitecture,
    },
    projectModule: {
        title: '功能模块',
        projecyModuleSubTit: '桥梁智能检测数据工厂的整体界面如下图所示：',
        moduleImg01: moduleImg01,
        moduleImgText01: '其中包含如下的处理模块：图像空间定位，深度学习病害筛选，海量图像拼接，病害几何测量，三维实景纹理可视化。数据工厂由计算机完成整个处理流程，无需人工干预，可在梁底数据采集完毕数小时内得到处理结果。',
        moduleImg02: moduleImg02,
        positionText01: '图像空间定位模块将对采集到的梁底图像进行空间位姿计算，获取每张图像在桥梁空间坐标系下的定位，用于后续图像拼接及病害空间分布，其成果形式如下图所示：',
        positionImg01: positionImg01,
        positionText02: '深度学习病害筛选模块将对图像进行多类型病害筛选，重点对裂缝病害和严重的块状病害进行快速筛选并标记，其成果形式如下图所示：',
        positionImg02: positionImg02,
        positionImg03: positionImg03,
        positionText04: '海量图像拼接模块将对所有梁底图像进行拼接处理，获取更大视场范围的单张拼接图像，对海量数据进行压缩，提取有效数据，用于对病害进行精确定位和可视化管理，其成果形式如下图所示：',
        positionImg04: positionImg04,
        positionImg05: positionImg05,
        positionText06: '病害几何测量和三维实景纹理可视化模块在拼接图像的基础上进行，用于对病害几何数据和空间走向进行精准测量，并对病害形态，走向，空间定位进行可视化展示，其成果形式如下组图所示：',
        positionImg06: positionImg06,
        positionImg07: positionImg07,
        positionImg08: positionImg08,
        positionImg09: positionImg09
    }
}]
// ---- 典型案例 ---- 数据工厂
export const factoryDemoData = [{
        id: 1,
        title: '(1)安徽铜陵长江大桥智能检测示范项目',
        main: '2018年11月，项目组与公规院检测公司在安徽铜陵长江大桥共同实施了桥梁智能检测示范项目，采用车载式移动数据采集平台自动采集了13跨梁底数据，采用数据工厂对数据进行了处理和分析。',
        imgs: [{
                key: 1,
                imgUrl: factoryDemoImg01
            },
            {
                key: 2,
                imgUrl: factoryDemoImg02
            },
            {
                key: 3,
                imgUrl: factoryDemoImg03
            },
            {
                key: 4,
                imgUrl: factoryDemoImg04
            }
        ]
    },
    {
        id: 2,
        title: '(2)湖南常德毛家岔大桥智能检测示范项目',
        main: '2019年12月，项目组承担了湖南省公路局桥梁智能检测示范项目，采用车载式移动数据采集平台自动采集三座省内普通公路桥梁，采用数据工厂对数据进行了处理和分析。',
        imgs: [{
                key: 1,
                imgUrl: factoryDemoImg05
            },
            {
                key: 2,
                imgUrl: factoryDemoImg06
            },
            {
                key: 3,
                imgUrl: factoryDemoImg07
            }
        ]
    }
]

// ---- 关于桥康 ---- 发展里程碑
export const qkBigvent = [{
    id: 1,
    date: '2013年',
    event: [{
            key: 1,
            eventText: '启动项目策划及市场调研'
        },
        {
            key: 2,
            eventText: '技术路线及整体研发方案'
        }
    ]
}, {
    id: 2,
    date: '2014年',
    event: [{
            key: 1,
            eventText: '注册成立湖南桥康智能科技有限公司'
        },
        {
            key: 2,
            eventText: '第1代BIR-X桥梁检测机器人研发启动'
        }
    ]
}, {
    id: 3,
    date: '2015年',
    event: [{
            key: 1,
            eventText: '第1代BIR-X桥梁检测机器人试制成功'
        },
        {
            key: 2,
            eventText: '桥康智能机器人产业园开工建设'
        }
    ]
}, {
    id: 4,
    date: '2016年',
    event: [{
            key: 1,
            eventText: '第2代BIR-X桥梁检测机器人试制成功'
        },
        {
            key: 2,
            eventText: '桥梁智能检测应用示范大范围启动'
        }
    ]
}, {
    id: 5,
    date: '2017年',
    event: [{
            key: 1,
            eventText: '桥梁智能检测机器人系统通过鉴定'
        },
        {
            key: 2,
            eventText: '通过高新技术企业与科技小巨人企业'
        },
        {
            key: 3,
            eventText: '第3代BIR-X桥梁检测机器人试制成功'
        }
    ]
}, {
    id: 6,
    date: '2018年',
    event: [{
            key: 1,
            eventText: '桥梁智能化检测规范标准立项'
        },
        {
            key: 2,
            eventText: '第3.5代BIR-X桥梁检测机器人产品定型'
        },
        {
            key: 3,
            eventText: '第1代CIR-X缆索机器人研发启动'
        },
        {
            key: 4,
            eventText: '交通部公路智能化养护管理应用示范项目'
        }
    ]
}, {
    id: 7,
    date: '2019年',
    event: [{
            key: 1,
            eventText: '第1代、第2代第CIR-X缆索机器人试制成功'
        },
        {
            key: 2,
            eventText: '缆索智能检测应用示范大范围启动'
        }
    ]
}, {
    id: 8,
    date: '2020年',
    event: [{
            key: 1,
            eventText: '第3代第CIR-X缆索机器人试制成功'
        },
        {
            key: 2,
            eventText: '形成桥康智能七大核心产品阵列'
        }
    ]
}]
// ---- 关于桥康 ---- 所获荣誉
export const honorsData = [{
        id: 1,
        imgs: [{
                key: 1,
                tit: '2017年获评高新技术企业',
                imgUrl: highTech
            },
            {
                key: 2,
                tit: '2017年获评科技创新小巨人企业',
                imgUrl: littleGiant
            }
        ]
    },
    {
        id: 2,
        tit: '2017年获评中国(小谷围)“互联网+交通运输”创新大赛二等奖',
        imgs: [{
                key: 1,
                imgUrl: xGuWei01
            },
            {
                key: 2,
                imgUrl: xGuWei02
            }
        ]
    },
    {
        id: 3,
        tit: '项目鉴定',
        imgs: [{
                key: 1,
                imgUrl: projectJD01
            },
            {
                key: 2,
                imgUrl: projectJD02
            }
        ]
    },
    {
        id: 4,
        tit: '专利---已授权专利12项｜专利布局100项',
        imgs: [{
            key: 1,
            imgUrl: patent
        }]
    },
    {
        id: 5,
        tit: '软著---已授权软件6项｜软著布局30项',
        imgs: [{
            key: 1,
            imgUrl: soft01
        }]
    }
]

// ---- 服务与支持 - 服务政策 
export const servicePolicy = [{
    id: 1,
    name: '服务承诺',
    content1: '秉承“用户至上、合作共赢”的服务宗旨，以“智能、精准、高效”为服务纲领，为客户提供桥梁智能检测整体解决方案。'
}, {
    id: 2,
    name: '团队文化',
    content1: '服务宗旨：用户至上、合作共赢。',
    content2: '服务纲领：科技、智能、高效。'
}, {
    id: 3,
    name: '服务热线',
    content1: '业务咨询：150 7318 7970',
    content2: '公司总机：0731-82255967'
}]

// ---- 服务与支持 - 市场服务
export const marketServiceData = {
    serviceModuleTit: '服务模式',
    productModuleTit: '产品模式',
    serviceObj: '服务对象',
    servicePro: '服务项目',
    paoductSales: '产品销售',
    govTit: '政府决策机构与行业主管部门',
    govTd01: '桥梁健康大数据服务平台构建',
    govTd02: '实现桥梁全生命周期健康数据标准化、数字化、结构化、可视化管理，为桥梁养护管理提供科学决策依据。',
    govDatas: [{
            key: 1,
            tit: '特定桥梁“互联网+”桥梁医生专家远程会诊',
            subTit: '快速提供权威的技术状况评价及处置方案决策，为桥梁安全处置和管理养护赢得宝贵时间。'
        },
        {
            key: 2,
            tit: '为桥梁管养招投标提供科学决策依据'
        },
        {
            key: 3,
            tit: '为桥梁预警提供科学依据'
        }
    ],
    bridgeTit: '桥梁管养部门与系统集成商',
    birdgeTd01: '区域桥梁健康大数据管养服务平台构建',
    bridgeDatas: [{
            key: 1,
            tit: '桥梁轻量化语义BIM病害管理系统构建'
        },
        {
            key: 2,
            tit: '特大桥梁底自动化检测系统定制化开发'
        },
        {
            key: 3,
            tit: '基于数字图像技术的公共设施轻量化安全监测平台构建'
        }
    ],
    thirdPartyTit: '第三方检测服务机构',
    thirdPartyTd01: '桥梁定检、特殊检查智能化检测技术服务',
    thirdPartyTd02: '采用车载式移动数据采集平台，自动采集桥梁梁底的图像数据，对数据进行处理和分析；提供三维可视化数据输出服务。',
    thirdPartyDatas: [{
            key: 1,
            tit: '斜拉索智能检测技术服务',
            subTit: '采用攀爬式移动数据采集平台，采集斜拉索表观图像和索塔图像，并对数据进行处理和分析。'
        },
        {
            key: 2,
            tit: '桥梁动态监测服务',
            subTit: '采用定制化硬件系统，基于视频数据动态处理平台，对大型桥梁重要部位和阻尼器、伸缩缝、支座等重要构件时程位移等信息实现数字化监测，为客户构筑精准、高效、低成本的桥梁轻量化检测系统。'
        }
    ],
    productModuleDatas: [{
            key: 1,
            tit: 'BIR-X桥梁智能检测机器人',
            subTit: '整机销售、租赁与定制化合作开发'
        },
        {
            key: 2,
            tit: 'CIR-X缆索智能检测机器人',
            subTit: '整机销售、租赁与定制化合作开发'
        },
        {
            key: 3,
            tit: 'OIR-X特大桥梁底自动化检测系统',
            subTit: '系统销售、租赁与定制化合作开发'
        },
        {
            key: 4,
            tit: '桥梁资产与运维数字化系统',
            subTit: '系统销售、部署与定制化合作开发'
        }
    ]
}

// ---- 服务与支持 - 营销网络
export const marketNet = [{
    id: 1,
    img: marketNetImg,
    tit1: '项目已在湖南、广东、福建、江苏、浙江、山东六省进行了典型桥梁的检测示范应用。',
    tit2: '跨区域缆索智能检测战略联盟正在形成。'
}]

// ---- 首页轮播图
export const homeCarousel = [{
        id: 1,
        img: carousel1
    },
    {
        id: 2,
        img: carousel2
    },
    {
        id: 3,
        img: carousel3
    },
    {
        id: 4,
        img: carousel4
    }
]

// ---- 关于桥康 ----> 桥康简介
export const qkIntroduction = [{
        id: 1,
        content: '湖南桥康智能科技有限公司是桥梁、隧道智能检测机器人研发、生产及桥梁隧道健康安全快速检测与评价、桥隧病害动态跟踪、桥梁科学管养及相关技术咨询服务的高科技公司，公司拥有一支涵盖机器视觉、自动控制、摄影测量、智能制造、大数据分析、桥梁隧道等多专业、多学科交叉组成的集成创新能力强的高水平专业技术团队，其中核心研发人员均为行业内学科带头人、专家。单位系省级“高新技术企业”、“科技创新小巨人企业”。'
    },
    {
        id: 2,
        content: '截至2018年底，我国仅公路桥梁已有85.15万座，居世界第一，桥梁安全问题是十分重大的民生问题，备受党和政府高度重视。为遏制桥梁安全运行十分严峻的形势，克服原有桥梁检测技术弊端，2014年以来，经过多年艰苦研制，研发出全球首台车载式桥梁智能检测机器人。该型桥梁智能检测机器人采用先进可靠的智能控制技术，可对桥梁进行全天候无损精细检测，能将检测数据进行定量计算分析，快速、准确评价桥梁运营情况，提出科学合理的养护维修技术方案，可为政府和桥管部门进行科学决策提供全方位技术服务。目前，该产品已经申请专利数十项，填补了国内外桥梁智能检测行业的空白。2018年，该项目被列为全国公路智能化养护管理应用试点建设项目。该标志性产品的诞生，以其卓越的集成技术性能和经济、高效、适应各种复杂环境的优越性，颠覆了传统人工桥梁检测模式，为实施桥梁自动化智能化检测开辟了道路。'
    },
    {
        id: 3,
        content: '我公司致力于桥梁智能检测机器人等各型产品开发及推广应用，联合政府部门制定行业标准，引领智能检测技术发展。目前，公司已成功开发BIR-X车载式桥梁智能检测机器人、CIR桥梁缆索检测机器人、OIR特大桥定制化智能检测装备等各型产品，满足桥梁健康数据采集的准确性、完整性和获取便捷性。通过开发基于BIM技术的桥梁信息三维化管理平台，构建了“互联网+桥梁医院”的病害诊断模式，显著提高了桥梁技术状况评价的准确性。当前，公司以智能机器人为纽带，通过建立利益共享机制，已构建了辐射全国多个省市的智能检测服务联盟。通过创新技术，精准服务，培育市场，企业核心竞争力不断提高。近年来，公司桥梁智能检测机器人先后参与了夏蓉、潭邵、京深、汕昆高速、铜陵长江大桥及地方公路、市政道路桥梁工程检测，获得了国家交通主管部门、各级政府、相关部门领导和广大用户的高度肯定。'
    },
    {
        id: 4,
        content: '桥梁智能检测技术的发展，呼应了行业迫切需要，符合国家产业政策要求。作为智能装备研制商和高科技咨询企业，我公司备受各级政府和相关部门领导高度重视，交通运输部、湖南省委省政府领导及长沙市委市政府和省市相关部门领导先后多次视察我公司，调研桥梁智能检测机器人研发、应用情况，对我公司产品、服务及经营举措给予了高度评价。'
    }
]

// ---- 关于桥康 ----> 桥康简介img
export const overlookImgs = [{
        id: 1,
        img: overLookImg1
    },
    {
        id: 2,
        img: overLookImg2
    }
]

// ---- 关于桥康 ----> 招贤纳士，公司简介
export const jobContact = [{
    id: 1,
    title: '投递方式',
    content1: '简历投递邮箱：hnqkzn@qq.com',
    content2: '湖南省长沙市雨花区黄土岭路260号2号办公楼东1-2层'
}]

// ---- 关于桥康 ----> 招贤纳士，招聘职位
export const jobPositionData = [{
    id: '1',
    titile: '图像拼接算法工程师',
    salary: '面议',
    address: '长沙市-雨花区',
    education: '本科/硕士/博士',
    experience: '3-5年',
    copanyName: '湖南桥康智能科技有限公司',
    tag1: '高新技术企业',
    tag2: '机器视觉',
    tag3: '人工智能',
    positionType: '研发工程师',
    joinDirectionName: '招聘方向',
    joinDirection: [{
            key: 1,
            article: '1、主路径：基于C++图像拼接、处理；'
        },
        {
            key: 2,
            article: '2、二级路径：图像测量，图像特征提取，三维重建，OPENCV、PCL、OSG，相机标定；'
        },
        {
            key: 3,
            article: '3、单独路径：深度学习（大V级别）；'
        },
        {
            key: 4,
            article: '4、主要工作场景：三维建筑结构平面照片全景拼接（单个建筑物结构3000+数量方块状照片），柱状结构物体环绕拍摄视频拼接（多个USB摄像头环绕拍摄）。'
        }
    ],
    joinAccuseTit: '岗位职责',
    joinAccuse: [{
            key: 1,
            article: '1、负责图像拼接、量测、处理，深度学习准确目标筛选；'
        },
        {
            key: 2,
            article: '2、研究并开发机器人在未知环境中的地图构建算法以及自主定位算法；'
        },
        {
            key: 3,
            article: '3、研究并开发机器人自主避障、自主路径规划以及运动规划算法；'
        },
        {
            key: 4,
            article: '4、研究并开发基于激光雷达、视觉、深度相机、超声波、IMU、里程计等多传感器融合的室内外场景感知与建模算法；'
        },
        {
            key: 5,
            article: '5、负责算法的优化、移植和产品化。'
        }
    ],
    joinClaimTit: '岗位要求',
    joinClaim: [{
            key: 1,
            article: '1、有较好数学基础，了解摄影测量和射影几何基本原理；'
        },
        {
            key: 2,
            article: '2、掌握计算机视觉和图像处理基本算法，并在如下一个或多个相关方向有较深入研究：相机标定、三维重建、图像测量、图像拼接、图像特征提取、增强现实、图像质量评价等；'
        },
        {
            key: 3,
            article: '3、熟练掌握C++和脚本语言编程(如Shell， Python， Perl等）；'
        },
        {
            key: 4,
            article: '4、熟悉OpenCV、PCL、VTK、OSG 等开源平台；'
        },
        {
            key: 5,
            article: '5、具有较强的文献获取能力，英文文献检索和阅读能力；'
        },
        {
            key: 6,
            article: '6、具有良好的沟通能力，和良好的团队合作精神；'
        },
        {
            key: 7,
            article: '7、有相关实践经验者优先。'
        }
    ],
    zlUrl: 'https://jobs.zhaopin.com/CC307481080J40026858709.htm'
}, {
    id: '2',
    titile: 'C++软件工程师',
    salary: '面议',
    address: '长沙市-雨花区',
    education: '本科/硕士/博士',
    experience: '1-3年',
    copanyName: '湖南桥康智能科技有限公司',
    tag1: '高新技术企业',
    tag2: 'C++开发',
    tag3: '人工智能',
    positionType: '研发工程师',
    joinDirectionName: '招聘方向',
    joinDirection: [{
            key: 1,
            article: '1、主路径：C++，MFC，QT，多线程编程，OpenCV，OSG，TCP、UDP、串口，熟悉图像处理；'
        },
        {
            key: 2,
            article: '2、主要工作场景：①集成算法开发桌面端数据处理软件（C++、MFC、QT）；②机器人采集端运动控制桌面端软件开发（C++、QT，多线程编程，OpenCV，OSG，TCP、UDP、串口）。'
        }
    ],
    joinAccuseTit: '岗位职责',
    joinAccuse: [{
            key: 1,
            article: '1、负责桌面端应用软件开发、算法集成开发;'
        },
        {
            key: 2,
            article: '2、负责软件技术方案编制与软件编写；'
        },
        {
            key: 3,
            article: '3、负责软件测试环境搭建与测试；'
        },
        {
            key: 4,
            article: '4、负责技术文档编写。'
        }
    ],
    joinClaimTit: '岗位要求',
    joinClaim: [{
            key: 1,
            article: '1、熟练掌握C++；'
        },
        {
            key: 2,
            article: '2、熟练多线程编程；'
        },
        {
            key: 3,
            article: '3、熟练掌握QT开发应用软件；'
        },
        {
            key: 4,
            article: '4、熟悉常用的通信协议TCP、UDP、串口等；'
        },
        {
            key: 5,
            article: '5、熟悉OSG开发；'
        },
        {
            key: 6,
            article: '6、熟悉OpenCV开发；'
        },
        {
            key: 7,
            article: '7、全日制本科以上学历；'
        },
        {
            key: 8,
            article: '8、1-3年以上开发经验，具有一定的图像处理经验；'
        },
        {
            key: 9,
            article: '9、性格开朗，有钻研自学能力。'
        }
    ],
    zlUrl: 'https://jobs.zhaopin.com/CC307481081J00430679805.htm'
}, {
    id: '3',
    titile: '新媒体运营',
    salary: '面议',
    address: '长沙市-雨花区',
    education: '大专',
    experience: '1-3年',
    copanyName: '湖南桥康智能科技有限公司',
    tag1: '高新技术企业',
    tag2: '市场推广',
    tag3: '人工智能',
    positionType: '新媒体运营',
    joinDirectionName: '招聘方向',
    joinDirection: [{
            key: 1,
            article: '1、Ps、Ai、视频剪辑、PPT，自媒体软文撰写；'
        },
        {
            key: 2,
            article: '2、市场推广，基本薪资+项目提成。'
        }
    ],
    joinAccuseTit: '岗位职责',
    joinAccuse: [{
            key: 1,
            article: '1、负责公司网站、微信公众号、微信视频号、微博、抖音的日常产品运营及推广工作；'
        },
        {
            key: 2,
            article: '2、根据社会时事、热点话题，结合公司产品及活动营销宣传需要，完成平台日常资讯、推文和软文撰写，负责跟踪推广效果，拥有数据驱动运营的思维，通过数据反馈，持续优化改进工作方法和内容；'
        },
        {
            key: 3,
            article: '3、负责公司产品内容输出的策略和执行：了解和跟踪行业动态及行业信息，关注竞品信息并定期整理相关报告；负责和产品技术部门沟通，提炼产品价值点，提供优质、有高度传播性的内容，撰写场景化稿件并对外输出；'
        },
        {
            key: 4,
            article: '4、掌握搜索引擎优化实践的知识,提升公司海内外知名度以及推动公司产品的销售业绩；'
        },
        {
            key: 5,
            article: '5、完成上级指定的其他工作。'
        }
    ],
    joinClaimTit: '岗位要求',
    joinClaim: [{
            key: 1,
            article: '1、25岁以下，五官端正；'
        },
        {
            key: 2,
            article: '2、大专以上学历，新闻传播、市场营销、艺术设计等相关专业，具有1年以上同岗位工作经验，优秀应届生可适当放宽要求；'
        },
        {
            key: 3,
            article: '3、网感好，创意优，执行力强，能抓住时事进行热点创作，能创造优质内容；'
        },
        {
            key: 4,
            article: '4、熟练掌握Ps、Ai、视频剪辑等图像处理软件和PPT办公软件使用技巧，具有PPT、图像、视频剪辑美化经验；'
        },
        {
            key: 5,
            article: '5、网感好，创新能力强，热爱运营岗位工作；'
        },
        {
            key: 6,
            article: '6、良好团队合作精神；较强的执行力，独立思考能力，观察力和应变能力，能灵活处理各种运营工作，可塑性强，思路清晰，会取长补短。'
        }
    ],
    zlUrl: 'http://jobs.zhaopin.com/CC307481081J00623602405.htm'
}]

// ----- 新闻动态 ---- 搭建模版data
/**
 * @description:mock数据简介 
 * id:id
 * largeTit:大标题
 * tit：文章标题
 * intro：文章第一段介绍
 * fromSource：来源
 * date：日期
 * url：视频URL
 * bgImg：视频初始化背景
 * content：文章文本区域
 * provideAuthor：提供人
 * content：{ key, title, subTit, midTitle, contentText, img, imgIntro } ;
 * title/subTit/midTitle:主/副/中间标题
 * contentText：段落文本
 * img/imgIntro：图片/图片介绍
 */
export const newTemplateData = [{
        id: '1',
        tit: '大国桥梁梦 · BIR-X车载式桥梁智能检测机器人开启桥梁检测智能化时代',
        fromSource: '来源：湖南桥康智能科技有限公司',
        date: '时间：2018.12.08',
        url: 'https://bim-1256217362.cos.ap-chengdu.myqcloud.com/videos/%E6%A1%A5%E6%A3%80%E5%90%88%E9%9B%86%E6%9C%80%E7%BB%88%E7%89%88_11_transcode_153077.mp4',
        bgImg: carousel4,
        content: [{
                key: 1,
                contentText: '湖南桥康智能科技有限公司自主研发、生产的BIR-X车载式桥梁智能检测机器人系统，以“机器换人”的检测方式，取代传统人工检测方式，开启了桥梁检测智能化时代。该套系统由数据自动采集机器人、数据智能处理与分析系统、桥梁三维结构信息与病害管理系统等组成，能对桥梁梁底、墩台、支座等桥梁重点部位进行全覆盖检查，裂缝病害识别精度达到或超过国家制定的检测标准，主要应用范围有：'
            },
            {
                key: 2,
                contentText: '1.健康检测：桥梁定期健康检查，桥梁全生命周期管养检查，预防性养护检查等。'
            },
            {
                key: 3,
                contentText: '2.专项调查：“互联网+”桥梁专家在线问题诊断，桥梁结构运行安全评估，维修前病害专项调查，维修加固工程量统计与复核等。'
            },
            {
                key: 4,
                contentText: '3.管养平台：桥梁在线数字建模、资产管理，桥梁健康大数据平台建设，桥梁全生命周期健康动态跟踪与管理等。'
            },
            {
                key: 5,
                contentText: '目前该机器人系统已在湖南、广东、安徽等省完成300多座桥梁实地检测，获得了国家交通主管部门、当地政府及相关部门领导和广大用户的高度评价。'
            }
        ],
        provideAuthor: '（供稿：王振 申阳军）'
    },
    {
        id: '2',
        tit: '大国桥梁梦 · 桥梁斜拉索检测“神器”：CIR-X缆索智能检测机器人',
        fromSource: '来源：湖南桥康智能科技有限公司',
        date: '时间：2019.10.08',
        url: 'https://bim-1256217362.cos.ap-chengdu.myqcloud.com/videos/%E6%A1%A5%E5%BA%B7%E6%99%BA%E8%83%BD%E8%A7%86%E9%A2%91%E6%8A%96%E9%9F%B3_1_transcode_153077.mp4',
        bgImg: carousel3,
        content: [{
                key: 1,
                contentText: 'CIR-X缆索智能检测机器人，面向斜拉索和悬索桥缆索检测、监测和维护而研发。机器人可沿缆索表面自动远距离爬行，快速对缆索、索塔及锚固区表观进行检测，实现数据自动采集、数据智能分析与处理、完整缆索特征重构、自动生成检测报告，是新一代高效、智能、攀爬式缆索检测机器人。'
            },
            {
                key: 2,
                contentText: '该型机器人能够适应50~260mm直径斜拉索和吊杆检测，可对斜拉索、索塔进行同步检测，同时作为攀爬式机器人平台，可搭载功能性组件进行缆索索力、断丝检测，缆索清洗、病害处置，缆索、索塔健康动态跟踪与管理。'
            },
            {
                key: 3,
                contentText: '目前该机器人系统已在湖南、广东、福建、江苏、浙江、山东等省完成多座桥梁斜拉索、索塔实地检测，被称为桥梁斜拉索检测“神器”。'
            }
        ],
        provideAuthor: '（供稿：王振 刘荣 申阳军）'
    },
    {
        id: '3',
        tit: '大国桥梁梦 · 桥康智能研发多型桥梁智能检测机器人系统',
        fromSource: '来源：湖南桥康智能科技有限公司',
        date: '时间：2020.05.08',
        url: 'https://bim-1256217362.cos.ap-chengdu.myqcloud.com/videos/%E6%A1%A5%E5%BA%B7%E6%99%BA%E8%83%BD%E5%AE%A3%E4%BC%A0_1_transcode_153077.mp4',
        bgImg: videoImg1,
        content: [{
                key: 1,
                contentText: '湖南桥康智能科技有限公司秉承“让中国桥梁更健康”的初心和使命，深耕桥梁智能检测行业，公司成立6年以来，根据桥梁检测不同应用场景，已自主研发、生产BIR-X车载式桥梁智能检测机器人、CIR-X缆索智能检测机器人、OIR-X特大桥梁底自动化检测系统等3款桥梁智能检测机器人产品，3款产品均由不同数据自动采集机器人、数据智能处理与分析系统、桥梁结构信息与病害管理系统等组成，其适用场景区别为：'
            },
            {
                key: 2,
                contentText: '1.BIR-X车载式桥梁智能检测机器人：为通用型桥梁智能检测机器人系统，除斜拉索、悬索桥等双索面结构因具有密集拉索阻碍机械臂展臂的桥梁外，适用于桥梁梁底病害检测。'
            },
            {
                key: 3,
                contentText: '2.CIR-X缆索智能检测机器人：适用于斜拉索桥、悬索桥的斜拉索、吊杆检测及斜拉索桥索塔检测等。'
            },
            {
                key: 4,
                contentText: '3.OIR-X特大桥梁底自动化检测系统：适用于结构复杂、检测环境恶劣，检测人员难以到达的大跨径桥梁底部检测。'
            },
            {
                key: 5,
                contentText: '上述桥梁智能检测机器人产品已在湖南、广东、安徽、福建、江苏、浙江、山东等省完成300多座桥梁得到广泛应用，深受用户好评。'
            }
        ],
        provideAuthor: '（供稿：王振 申阳军 陈晓辉）'
    },
    {
        id: '4',
        tit: '大国桥梁梦 · 新一代人工智能技术助推桥梁管养转型',
        fromSource: '来源：湖南桥康智能科技有限公司',
        date: '时间：2019.02.08',
        intro: '桥梁工程是咽喉工程，事关百年大计，是国民生产生活的生命线。桥梁工程的安全运营直接关系到国计民生，一旦出现安全事故，将直接危害人民群众的生命安全。桥梁健康状况是各级政府、主管部门对桥梁进行日常管理和决策的最主要依据，贯穿桥梁全生命周期，因此桥梁健康数据的准确性、完整性和获取便捷性对桥梁的科学养护和管理至关重要。',
        content: [{
                key: 1,
                title: '一、桥梁管养的背景',
                contentText: '经过近半个世纪的建设，我国公路桥梁建设取得了举世瞩目的成就。截至2019年底，全国公路桥梁87.83万座、6063.46万米，居世界第一，其中特大桥梁5716座、1033.23万米，大桥108344座、2923.75万米。然而，暴露在自然界中的桥梁，随着暴雨、洪水、冰雪、日晒、冻融等自然因素的侵蚀破坏以及桥龄的增加，加上车辆超限超载导致的桥梁服役条件的恶化，桥梁构件性能就不可避免产生老化、退化和损坏。据不完全统计,目前国内公路网中技术等级为三、四级的带病桥梁所占比例高达30%，超10万座桥梁被评定为危桥。'
            },
            {
                key: 2,
                contentText: '近十多年来，随着公路桥梁建设的迅猛发展，我国桥梁管养已从“重建轻养”向“建养并重”的时代转变，桥梁养护管理也随即进入“体量大、要求高”的新阶段，养护管理面临“质”和“量”的双重压力。'
            },
            {
                key: 3,
                contentText: '2015年4月，交通运输部加入了“互联网+”行动计划编制工作组，旨在应用大数据技术支撑交通运输行业科学决策；2018年12月14日，交通运输部党组会议指出“深刻认识加快发展新一代人工智能的重大意义，促进人工智能与交通运输深度融合，加快建设交通强国”；《“十三五”公路养护管理发展纲要》要求“着力推进养护管理创新，特别是要推动互联网、大数据等新技术与管理深度融合，助推管理创新，提升管理效能”。通过桥梁管养与互联网、大数据等新一代人工智能技术的深度融合，助推桥梁管养转型。'
            },
            {
                key: 4,
                title: '二、桥梁管养的现状与痛点',
                contentText: '现阶段，在进行桥梁安全运行健康检查时，主要是通过周期性巡检、定检，对桥梁结构件进行外观病害检查，通过检查获取的数据分析评估其健康状况。因此如何及时、全面、准确地获取桥梁的健康数据，并进行有效管理和科学决策，是确保桥梁安全运行的关键，但是，受到桥梁检测技术发展等诸多因素制约，目前桥梁健康数据的获取手段、质量以及管理均存在着问题，极大制约了桥梁技术状况评价的准确性，主要体现在以下几个方面：'
            },
            {
                key: 5,
                subTit: '1、桥梁检测单位的痛点',
                contentText: '首先，检测数据获取手段以人工检测为主，属于劳动密集型产业，人工采集检测数据主要工作量巨大，数据采集方式：眼睛看→手工记→人工录，原始信息记录工作量大，数据过于碎片化、容易丢失，人工和设备成本高易造成检测成本高、数据获取不全面。',
                img: transformation01,
                imgIntro: '图1 传统人工检测：“眼睛看→手工记→人工录”工作量巨大'
            },
            {
                key: 6,
                contentText: '其次，人工对桥梁结构件外观健康诊断结果带有极大的主观经验性，同时人工采集的数据质量参差不齐，结果偏主观，误诊、漏诊事件时有发生。如何让最有经验的技术人员通过客观、全面的检测数据，还原桥梁结构件实际健康状况，是对桥梁安全进行准确诊断的关键。'
            },
            {
                key: 7,
                contentText: '第三，健康诊断不仅需要当期检测的数据量值，还需要数据量值历史变化情况，人工检测数据缺乏标准性，无法进行定量对比，同时还需参考很多其它资料，信息获取过于碎片化，进行全面分析难度很大。'
            },
            {
                key: 8,
                subTit: '2、桥梁管养单位的痛点',
                contentText: '首先，检测成果以纸质报告为主要形式，多以文字表述、配以表格、图片为主，数据不直观、不全面，数据来源单一、解读较困难，碎片化现象严重，即使建立信息管理系统，也无法有效进行大数据分析，无法从根本上解决信息孤岛问题。',
                img: transformation02,
                imgIntro: '图2 传统人工文字表述方式，数据总体质量差，信息获取渠道单一，数据不直观'
            },
            {
                key: 9,
                contentText: '其次，检测数据标准难以统一。管养单位管理桥梁众多，每批次检测获取的纸质检测报告无法直观对比，数据延续性差，不利于综合分析诊断。尽管很多管养单位都建立了相应的信息管理系统，但仍无法从根本上解决信息准确、完整、及时录入系统等问题，无法更有效地进行多视角、多维度数据综合分析，不利于科学决策。'
            },
            {
                key: 10,
                contentText: '第三，决策效率低下。每遇到疑难杂症，均需邀请资深专家到场听取检测单位汇报甚至实地查看现场，过程中将花大量的时间和精力，而将检测结果复原于现场实际，则可能出现理解偏差、疏漏等情况，造成判断结果不一，存在无法对症下药等风险。'
            },
            {
                key: 11,
                contentText: '第四，政府及行业主管部门及时、准确、全面地获取信息汇总成本大，管理难度大，决策滞后，并带有一定盲目性。由于检测项目中标的检测单位不一，检测结果描述标准不一致，无法持续跟踪桥梁使用现状，即使不同单位、不同时间发现同一处病害，病害演变的轨迹依然无法获取，易导致桥梁病害无法及时处理。与此同时，与桥梁工程相关的设计、施工单位也有迫切期望第一时间获取的相关信息的需求，便于总结、改进工程的工艺、品质等，以保持市场竞争中的主动性，而传统检测方式是无法及时、准确、全面地获得相关信息的。'
            },
            {
                key: 12,
                title: '三、新一代人工智能技术助推桥梁管养转型',
                contentText: '针对我国桥梁运行严峻的形势，及行业向人工智能技术助力科学决策转型的迫切需求，湖南桥康智能科技有限公司经多年研究，提出了通过新一代人工智能技术助推桥梁管养转型的解决方案：'
            },
            {
                key: 13,
                contentText: '一是通过机器视觉、深度学习等人工智能技术，打造自动化、智能化检测装备，构建公共基础设施安全管理平台，实现从数据采集、录入、成果实景呈现等全链条无缝自动对接，从而大大提高数据质量。'
            },
            {
                key: 14,
                contentText: '二是通过“互联网+”桥梁医院诊断模式，实现专家为远程桥梁会诊，通过提供权威的技术状况评价及处置方案，结合数据深度挖掘和大数据分析，最终建立实现科学决策的一体化管养服务平台。'
            },
            {
                key: 15,
                contentText: '通过对新一代人工智能技术的广泛应用，从而精准助推桥梁管养转型。',
                img: transformation03,
                imgIntro: '图3  桥康智能提供的一揽子解决方案'
            },
            {
                key: 16,
                midTitle: '（一）依托新一代人工智能技术，成功研发全球首款车载式桥梁智能检测机器人，实现全面自动化采集桥梁数据，精准掌握第一手桥梁健康状况数据',
                subTit: '1、BIR-X车载式桥梁检测智能机器人简介',
                contentText: '湖南桥康智能科技有限公司研制的BIR-X车载式桥梁检测智能机器人，是根据我国桥梁检测行业发展现状而开发的、拥有自主知识产权的智能化检测系统，综合利用信息时代高速发展的光电传感器，以车载机械臂+机械手为平台，集成高分辨率工业相机、结构光相机、线激光扫描器、微惯导等先进的传感器设备，在运载车辆+机械臂+机械手联合移动下，智能桥梁检测机器人可对桥梁进行全方位、无死角地快速扫描，实现各类型桥梁底部图像的自动采集。',
                img: transformation04,
                imgIntro: '图4 桥梁检测智能机器人系统结构简图'
            },
            {
                key: 17,
                subTit: '2、桥梁智能检测机器人的特点',
                contentText: '（1）首先通过机器换人，规避了在劳动密集型产业中因作业环境恶劣而导致的人工作业不安全、低效、数据主观等风险；通过自动规划采集路径，使得桥梁检测区域无死角检测，实现数据完整性采集；'
            },
            {
                key: 18,
                contentText: '（2）检测成果具有标准化、数字化、结构化的特点，可与管养系统自动对接与上传，打通数据从采集、录入、成果实景呈现等全链条无缝自动对接，避免数据信息遗漏、失真，具备很强的溯源性和延续性；'
            },
            {
                key: 19,
                contentText: '（3）桥梁智能检测机器人在桥底数据采集过程中，在获取桥梁影像数据的同时，采集了桥梁结构的点云数据和每一帧影像的位置姿态信息，实现每一条病害与桥梁部构件精准关联，进而实现病害在时间轴上动态比对，实现病害动态化跟踪。',
                img: transformation05,
                imgIntro: '图5 桥梁检测智能机器人的应用是桥梁检测领域典型机器换人案例'
            },
            {
                key: 20,
                midTitle: '（二）开发基于BIM技术的桥梁三维信息管理平台，着力提高桥梁技术状况评价准确性',
                subTit: '1、基于BIM技术的桥梁三维信息管理平台简介',
                contentText: '深刻理解病害及其在空间的分布，对于了解桥梁结构件服役状况、分析病害成因、制订维修方案至关重要，邀请资深专家到场听取检测单位汇报甚至看现场，一般来说，是规定动作，但这一过程要花费大量的时间和精力。让桥梁专家在办公室就能看到实景呈现，使专家清楚了解病害数量和量测尺寸及其在空间的分布，免去舟车劳顿之苦，同时能够赢得宝贵的处置时间，湖南桥康智能科技有限公司提出了“互联网+”桥梁医院的解决方案，就是解决问题的新途径。'
            },
            {
                key: 21,
                contentText: '该方案的技术特点，就是通过构建基于BIM技术的桥梁三维信息管理平台，将桥梁病害等信息自动加载在桥梁三维模型之上，形成含有桥梁基本信息、部构件信息、病害信息、加固维修处置信息等数据的桥梁三维信息模型，并通过互联网技术，远程实时传输到专业对口的技术专家办公室，技术专家能够如亲临现场般通过对桥梁三维信息模型全面了解桥梁实际健康状况，为桥梁进行远程会诊。',
                img: transformation06,
                imgIntro: '图6 基于BIM技术的桥梁三维信息管理平台'
            },
            {
                key: 22,
                subTit: '2、桥梁三维信息管理平台的特点',
                contentText: '（1）实现桥梁资产管理的数字化、可视化、自动化'
            },
            {
                key: 23,
                contentText: '我国桥梁资产具有规模巨大、使用周期长、管理难度大的特征。桥梁管养者面对建设方大范围移交过来的桥梁资产，类型、数量、状态是一个非常庞大的数据库，再加上后期所涉及的日常养护、加固维修等信息，实时准确掌握资产的变动情况非常困难。'
            },
            {
                key: 24,
                contentText: '桥康智能开发的三维信息管理平台在实现桥梁资产管理方面解决了以上行业痛点，其功能特点如下：'
            },
            {
                key: 25,
                contentText: '第一，桥梁资产三维数字化交工；'
            },
            {
                key: 26,
                contentText: '第二，桥梁资产三维可视化浏览，桥梁三维信息模型将桥梁资产从整体、局部及细部信息更直观地呈现；'
            },
            {
                key: 27,
                contentText: '第三，桥梁资产三维化管理，该系统里构件三维与各类信息关联，将工程数据等非结构化数据统一标准结构化，搭建完整的桥梁信息档案，可快速查询构件信息，包括实时数据与历史数据，从根本上解决信息孤岛问题；并通过加载构件全寿命过程信息，病害信息与构件自动关联、自动更新，病害可追溯、可对比。'
            },
            {
                key: 28,
                contentText: '（2）远程诊断'
            },
            {
                key: 29,
                contentText: '通过该管理平台，打通了“现场”与“医院”界限，打通了现场作业时间与专家诊断时间界限，打通了专业学科界限，让最适合该类病害专业的桥梁专家通过平台如身临其境般还原结构物实际现状，对桥梁健康状况进行准确诊断，并提出维修处置建议。'
            },
            {
                key: 30,
                title: '四、对新一代人工智能技术助推桥梁管养转型的建议',
                contentText: '桥梁科学养护和管理是一个系统工程，需交通主管部门予以大力支持，为此，提出以下建议：',
            },
            {
                key: 31,
                contentText: '1、大力推广桥梁智能化快速化检测技术。建议在桥梁定检、巡检中全面采用桥梁智能快速检测技术，为信息化管理创造条件，为桥梁科学管养奠定基础。建议在条件比较成熟的省市，或市场管理主体比较单一的桥梁管理机构先行先试，然后逐步推广。',
            },
            {
                key: 32,
                contentText: '2、全面应用互联网、大数据、BIM技术，提高桥梁科学管养决策能力。建议按照“一桥一模型，一省一平台”模式，建立基于互联网、大数据、BIM技术的桥梁科学管养决策系统，实现大数据支撑的桥梁养护科学决策。',
            },
            {
                key: 33,
                contentText: '3、尽快出台桥梁智能化快速化检测技术标准，引导桥梁快速检测技术发展。',
            },
            {
                key: 34,
                contentText: '全面实施桥梁科学管理，是桥梁养护管理的必经之路。我们相信在交通运输部的正确领导下，在各级主管领导的全力推动下，科学养护之路一定越走越宽，桥梁运营一定会越来越安全。',
            }
        ],
        provideAuthor: '（供稿：陈晓辉 谭儒平）'
    },
    {
        id: '5',
        largeTit: '杯水也要灭火',
        tit: '桥康故事 · 让中国桥梁更健康',
        fromSource: '来源：湖南桥康智能科技有限公司',
        date: '时间：2020.09.24',
        content: [{
                key: 1,
                img: water01
            },
            {
                key: 2,
                contentText: '杯水车薪这个成语说的是一车的柴着火了，靠一杯水难以熄灭。但是，有一群人却不这么想，他们认为，如果在薪刚刚点着时，或者尚未形成大势之时，这一杯水就会发挥至关重要的作用，关键在于洞察苗头，把握时机，把握态势。'
            },
            {
                key: 3,
                contentText: '集合在桥康智能旗帜下的年轻科学家、工程师，就是这样一群自喻为“一杯水”的灭火人，他们要做的，就是通过桥梁智能检测的“火眼金睛”，早发现、早判断、早处理，消除令人难以承受的桥梁垮塌灾难。'
            },
            {
                key: 4,
                contentText: '中国是世界第一桥梁大国，目前，桥梁总数达100万余座，基建狂魔在建造宏伟、新颖的大桥时，不知成就了多少个世界第一。'
            },
            {
                key: 5,
                contentText: '“一桥飞架南北，天堑变通图”，这是怎样的快意人生啊！'
            },
            {
                key: 6,
                contentText: '然而，悲催的是，世界桥梁在不断“野蛮”生长，而世界桥梁检测技术却不断地拖后腿，几十年过去，检测桥梁是或有病害，是或能健康运行，依靠的仍然是人工，需要工程师用自己的眼睛，凭借自己的经验，判断桥梁病害。多少检测工程师因此掉落桥下，命丧黄泉。'
            },
            {
                key: 7,
                contentText: '“一定要实现无人检测！”'
            },
            {
                key: 8,
                contentText: '“一定要实现智能检测！”'
            },
            {
                key: 9,
                contentText: '于是，六年前的金秋时节，怀揣“让世界桥梁更健康”梦想的第一批专家教授和工程师创建了桥康智能公司，这个仅仅“一杯水”的平台。',
                img: water02
            },
            {
                key: 10,
                contentText: '杯水也能灭火！'
            },
            {
                key: 11,
                contentText: '杯水也要灭火！'
            },
            {
                key: 12,
                contentText: '曾几何时，梦想的感召，几年间，这个平台居然聚集了一支涵盖机器视觉、自动控制、摄影测量、智能制造、大数据分析、桥梁隧道等多专业、多学科的高水平技术团队，其中核心研发人员均为行业内学科带头人、专家。'
            },
            {
                key: 13,
                contentText: '梦想是玄幻的，但目标是明确的。'
            },
            {
                key: 14,
                contentText: '四年前的一个春天，当第一台车载式智能检测机器人在长沙市郊一座桥梁首次检测时，国内桥梁界几名大咖惊呆了！桥梁智能检测机器人像“B超”机，轻轻在桥梁底部一扫，各种状况全收集，N张照片通过大数据分析，即刻精准定位桥梁病害。这太神奇了！久远的梦想终于照进现实，于是大咖们肯定：杯水也能灭火！要把一杯水汇聚成千万杯水，形成高山流水之势！'
            },
            {
                key: 15,
                contentText: '此时，正在为桥梁检测智能化、快速化、自动化焦虑的国家级专家获悉全球首台桥梁智能检测机器人研制成功时，兴奋不已，立即现场察看。不久，桥梁智能检测项目被交通运输部列为全国公路智能化养护应用试点项目。'
            },
            {
                key: 16,
                contentText: '风正一帆悬，千里江陵近。又几年，桥康智能的这杯水，乘势而下，针对桥梁检测的要求，迅速开发了斜拉桥缆索检测机器人、桥梁轻量化语义BIM病害管理系统构建、特大桥梁底自动化检测系统定制化开发、基于数字图像技术的公共设施轻量化安全监测平台构建、桥梁三维检测APP、桥梁资产与运维数字化系统等一系列智能化产品。'
            },
            {
                key: 17,
                contentText: '看似一气呵成，实在艰苦备尝。'
            },
            {
                key: 18,
                contentText: '于是，代替人工检测的各种桥梁智能检测机器人，出现在铜陵长江大桥、润扬长江大桥、滨州黄河大桥、珠江特大桥、舟山跨海大桥、福建泉州大桥等检测现场。机器代人，智能检测，这是多少业内人士的期盼啊！看来，想不成为“网红”都难。'
            },
            {
                key: 19,
                contentText: '桥梁检测，过去那种令人望而生畏，风险莫测的高空作业，顿时变得安全、便捷。桥梁状况电子数据成就了信息化处理，对政府和主管部门及专业工程师来说，坐在办公室电脑前，想查就查，想看就看，既可评估桥梁病害，又可制定桥梁科学管养方案，还可为桥梁预警提供科学依据，凡此种种，想想都过瘾！'
            },
            {
                key: 20,
                contentText: '然而，新生事物毕竟是一颗幼苗，要成长为参天大树，需要太多呵护。领导的关怀、政策的引导、市场的呼应、同仁的助力……一个都不能少。我们相信杯水能灭火，千千万万杯水更能！让中国桥梁乃至世界桥梁更健康，是一种情怀，是一种责任，更是一种担当。'
            },
            {
                key: 21,
                contentText: '我们渴望增添您的那杯水为桥梁安全运营助力！'
            },
            {
                key: 22,
                contentText: '为人民群众生命财产安全保驾护航！'
            }, {
                key: 23,
                contentText: '涓水虽细，汇流可成河。'
            },
            {
                key: 24,
                contentText: '青山遮不住，毕竟东流去。'
            },
            {
                key: 25,
                contentText: '自然的力量如此，信念的力量也必如此！'
            },
            {
                key: 26,
                contentText: '期待与您同行，期待您那关键的一杯水！'
            }
        ],
        provideAuthor: '（供稿： 谭儒平）'
    }
]