import './index.scss';
import React from 'react';
import { BackTop } from 'antd';
import { VerticalAlignTopOutlined, WechatOutlined } from '@ant-design/icons';
import { routeData, wxImg, wxkfImg, dyImg } from '@/common/index';

const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#0285CE',
    color: '#fff',
    textAlign: 'center',
    fontSize: 30
}

function Footer() {
    return (
        <div className="footer">
            <div className='footer-top'>
                {
                    routeData.map(item => {
                        const { name, path, routes } = item;
                        if (path === '/about-us') {
                            return (
                                <div className="footer-about" key={path}>
                                    <h3>{name}</h3>
                                    {routes && routes.map(i => {
                                        const { name, path } = i;
                                        return (
                                            <div key={path}>
                                                <div><a href={path}>{name}</a></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                }
                {
                    routeData.map(item => {
                        const { name, path, routes } = item;
                        if (path === '/product-center') {
                            return (
                                <div className="footer-produuct" key={path}>
                                    <h3>{name}</h3>
                                    {routes && routes.map(i => {
                                        const { name, path } = i;
                                        if (path === '/product-center/app' || path === '/product-center/bim') { // 产品中心暂未内容二级导航处理
                                            return (
                                                <div key={path}>
                                                    <div><span>{name}</span></div>
                                                </div>
                                            )
                                        }
                                        return (
                                            <div key={path}>
                                                <div><a href={path}>{name}</a></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        } else {
                            return null
                        }
                    })
                }
                <div className='footer-contact'>
                    <h3>联系方式</h3>
                    <div>地址：湖南省长沙市雨花区黄土岭路260号2号办公楼东1-2层</div>
                    <div>公司总机：0731-82255967</div>
                    <div>业务咨询：150 7318 7970</div>
                    <h3>官方新媒体</h3>
                    <div>官方微信客服：hnqkzn</div>
                    <div>官方微信公众号：桥康智能 | CNQKZN </div>
                    <div>官方微信视频号：桥康智能</div>
                    <div>官方抖音号：桥康智能</div>
                    <div>投稿邮箱：hnqkzn@qq.com</div>
                </div>
                <div className='footer-new-media'>
                    <h3>新媒体二维码</h3>
                    <div className='qr-code'>
                        <div>微信公众号</div>
                        <img src={wxImg} alt='wx' className='wx-img' />
                    </div>
                    <div className='qr-code'>
                        <div>微信客服</div>
                        <img src={wxkfImg} alt='wxkf' className='wxkf-img' />
                    </div>
                    <div className='qr-code'>
                        <div>抖音</div>
                        <img src={dyImg} alt='tiktok' className='dy-img' />
                    </div>
                </div>
            </div>
            <div className='footer-copyright'>
                <span>Copyright © 2020 湖南桥康智能科技有限公司 |<a className='icp' target="_blank" rel="noopener noreferrer" href='https://beian.miit.gov.cn/#/Integrated/index'>湘ICP备18010963号</a></span>
            </div>
            <div className='wx-area'>
                <WechatOutlined />
                <img src={wxImg} alt='wx' className='wx-img' />
            </div>
            <BackTop className='back-top' visibilityHeight={100}>
                <div style={style}>
                    <VerticalAlignTopOutlined />
                </div>
            </BackTop>
        </div>
    )
}

export default Footer;